<template>
    <v-container
        class="pb-5"
        fluid
    >
        <v-dialog v-model="modal_start_project" width="600px">
            <v-card>
                <v-card-text class="pt-3 mb-0 pb-0">
                    <h2 class="mb-3 mt-2">Préparer un entretien</h2>
                    <p>
                        Vous allez préparer un entretien à partir de l’auto-diag.<br>
                        Si d’autres auto-diag concernent cet établissement, une question à vous poser : souhaitez-vous regrouper tous les auto-diag et faire un seul entretien ?<br>
                        Si oui, vous devez alors choisir un auto-diag auquel rattacher l’entretien unique (l’auto-diag « chef de file »).<br>
                        Attention : dans ce cas, les autres auto-diag ne doivent pas avoir d’entretien en préparation, sinon vous ne pourrez pas les grouper ensemble.<br>
                    </p>
                </v-card-text>
                <div class="pb-5 text-center">
                    <v-btn @click="createProject(selected_item)" color="primary" small outlined dark class="mr-2">Préparer l'entretien</v-btn>
                    <v-btn color="darken-1" small text @click="modal_start_project = false">Annuler</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-card class="mb-5">
            <v-card-text>
                <h3 class="text--primary">
                    Entretiens de votre agence
                </h3>
            </v-card-text>
            <v-row>
            <v-col cols="12" v-if="this.user.role !== 'user'">
              <v-card-text class="py-4 pa-4">
                Filtrer par agence :
                <v-select :items="[{ id: 'all', name: 'Toutes les agences' }, ...this.user_agencies]" :item-text="'name'" :item-value="'id'" @change="filterEmployerByAgency($event)">
                  <!-- each agencies of user -->
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Agence</v-list-item-title>
                      <v-list-item-subtitle>Agence</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-select>
              </v-card-text>
            </v-col>
          </v-row>
            <v-data-table
                :headers="headers"
                :items="projects"
                :options.sync="options"
                :server-items-length="totalProjects"
                :loading="loading"
                :no-data-text="'Aucun entretien pour le moment'"
                fluid
            >
                <template v-slot:item.compare="{ item }">
                    <div class="text-center">
                        <input type="checkbox" v-model="compare" :value="item" :disabled="compare.length >= 4 && compare.indexOf(item) < 0" />
                    </div>
                </template>

                <template v-slot:item.company="{ item }">
                    <template v-if="item.project && item.project.employers && item.project.employers.length > 0">
                        <v-tooltip top color="black" :max-width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="black" v-bind="attrs" v-on="on">mdi-link</v-icon>
                            </template>
                            <span>
                                <span v-for="(employer, index) in item.project.employers" :key="'e_'+employer.id">
                                    <template v-if="index > 0">/ </template>{{ employer.company }}
                                </span>
                            </span>
                        </v-tooltip>
                    </template>
                    {{ item.company }}
                </template>

                <template v-slot:item.date_appointment_clean="{ item }">
                    <template v-if="item.date_appointment && item.date_appointment.length > 0">
                        {{ cleanDate(item.date_appointment) }}
                    </template>
                </template>

                <template v-slot:item.conseiller="{ item }">
                    {{ item.user.firstname }} {{ item.user.lastname }}
                </template>

                <template v-slot:item.actions="{ item }">
                    <Autodiagnostic v-if="item.project && item.project.employers" :employer="item" :employers="item.project.employers" @reload="getProjects" />
                    <Autodiagnostic v-else :employer="item" @reload="getProjects" />
                    <template v-if="item.project && item.project.id">
                        <template v-if="item.project.status === 'Terminé'">
                            <v-btn :to="{ name: 'interviews.see', params: {id: item.project.id}}" color="green" small outlined dark class="mb-1 mt-1 mr-2">Consulter l'entretien</v-btn>
                            <v-btn :to="{ name: 'interviews.editreport', params: {id: item.project.id}}" color="warning" small outlined dark class="mt-1 mb-1 mr-2">Modifier le rapport</v-btn>

                            <template v-if="loadingPdf && loadingPdf.indexOf(item.project.id) != -1">
                                <v-btn class="mr-2 mt-1 mb-1" small disabled>Génération en cours...</v-btn>
                            </template>
                            <template v-else>
                                <v-btn small class="mr-2 mt-1 mb-1" text @click="downloadReport(item.project.id)"><v-icon>mdi-download</v-icon> Générer le rapport</v-btn>
                            </template>

                        </template>
                        <template v-else>
                            <v-btn :to="{ name: 'interviews.edit', params: {id: item.project.id}}" color="primary" small outlined dark class="mr-2 mb-1 mt-1">Éditer l'entretien</v-btn>
                            <v-btn :to="{ name: 'interview', params: {id: item.project.id}}" color="black" small outlined dark class="mb-1 mt-1">Démarrer l'entretien</v-btn>
                        </template>
                    </template>
                    <template v-else>
                        <v-btn @click="selectItem(item)" color="primary" small outlined dark>Préparer l'entretien</v-btn>
                    </template>
                </template>
            </v-data-table>

            <CompareAutodiags :compare="compare" :actions="['Comparer']" />
        </v-card>

    </v-container>
</template>

<script>
import Autodiagnostic from '@/components/Autodiagnostic';
import moment from 'moment';
import downloadPDF from '@/functions/downloadPDF.js';
import CompareAutodiags from '@/components/CompareAutodiags';

export default {
    data() {
        return {
            user: this.$store.getters.user,
            user_agencies: [],
            current_agency: 'all',
            loading: false,
            modal_start_project: false,
            selected_item: false,
            loadingPdf: [],
            projects: [],
            totalProjects: 0,
            options: {
                page: 1,
                itemsPerPage: 10
            },
            headers: [
                { text: 'Action', value: 'compare', sortable: false },
                { text: 'Entreprise', value: 'company', sortable: false, },
                { text: 'SIRET', value: 'siret', sortable: false, },
                //{ text: 'Contact', value: 'email', sortable: false, },
                { text: 'Conseiller', value: 'conseiller', sortable: false, },
                { text: "Date de l'entretien", value: 'date_appointment_clean', sortable: false, },
                { text: 'Statut', value: 'project.status', sortable: false, },
                { text: 'Liens', value: 'actions', sortable: false, }
            ],
            loadingProject: false,
            compare: []
        }
    },
    created() {
        //this.getProjects();
        if (this.user.role === 'admin') {
          this.getAgencies();
        }
        else {
          let users_agencies = JSON.parse(this.user.agencies_ids);

          users_agencies.forEach(agency => {
            this.getAgencyById(agency);
          });
        }
    },
    methods: {
        getAgencies() {
          this.$http
            .get(process.env.VUE_APP_API + '/agencies')
            .then(response => {
              this.user_agencies = response.data.agencies;

              this.user_agencies.forEach(agency => {
                this.getAgencyById(agency.id);
              });
            }).catch( () => {
              return false;
            });
        },
        getAgencyById(id) {
          this.$http
            .get(process.env.VUE_APP_API + '/agency/' + id)
            .then(response => {
              this.user_agencies.push(response.data.agency);
            }).catch( () => {
              return false;
            });
        },
        getProjects() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/agency?agency=' + this.current_agency + '&page=' + this.options.page + '&itemsPerPage=' + this.options.itemsPerPage)
                .then(response => {
                    this.projects = response.data.data;
                    this.totalProjects = response.data.meta.total;
                    this.options.page = response.data.meta.current_page;
                    this.options.itemsPerPage = response.data.meta.per_page;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        createProject(employer) {
            this.loadingProject = true;
            this.$http
                .post(process.env.VUE_APP_API + '/projects', {
                    employer_id: employer.id
                })
                .then( response => {

                    // test data is sent
                    if( response.data.data.id ) {
                        // redirect to results
                        this.$router.push({
                            name: 'interviews.edit',
                            params: {
                                id: response.data.data.id,
                            }
                        });

                    }
                    this.loadingProject = false;

                }).catch( () => {
                    this.loadingProject = false;
                });
        },
        cleanDate(date) {
            return moment(String(date)).format('DD/MM/YYYY HH:mm');
        },
        downloadReport(project_id) {
            this.loadingPdf.push(project_id);
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + project_id + '/report', {}, { responseType: 'arraybuffer'} )
                .then(response => {
                    downloadPDF(response.data, 'rapport');
                    let index = this.loadingPdf.indexOf(project_id);
                    if (index !== -1) this.loadingPdf.splice(index, 1);
                }).catch( () => {
                    let index = this.loadingPdf.indexOf(project_id);
                    if (index !== -1) this.loadingPdf.splice(index, 1);
                });
        },
        selectItem(item) {
            this.selected_item = item;
            this.modal_start_project = true;
        },
        filterEmployerByAgency(agency) {
          let request = '';

          if (agency === 'all') {
            request = process.env.VUE_APP_API + '/projects/agency';
          }
          else {
            this.current_agency = agency;
            request = process.env.VUE_APP_API + '/projects/agency?agency=' + this.current_agency;
          }

          this.loading = true;
          this.$http
              .get(request)
              .then(response => {
                this.projects = response.data.data;
                this.totalProjects = response.data.meta.total;
                this.options.page = response.data.meta.current_page;
                this.options.itemsPerPage = response.data.meta.per_page;
                this.loading = false;
              }).catch( () => {
            this.loading = false;
          });
        }
    },
    watch: {
        options: {
            handler () {
                this.getProjects();
            },
            deep: true,
        },
    },
    components: {
        Autodiagnostic,
        CompareAutodiags
    }
}
</script>
